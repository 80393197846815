import React, { ReactNode, useEffect, useRef } from 'react';
import './MemberAccordian.scss';
import { Member } from '../../utilities/types';
import MemberCard from '../memberCard/MemberCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function MemberAccordian(props: {
  title: string, description?: string, open?: boolean, list: Member[]
  images: { [key: string]: string }, children?: ReactNode
}) {

  let accordEl = useRef<HTMLDivElement>(null);
  let canPlusEl = useRef<SVGSVGElement>(null);

  useEffect(() => {
    if (props.open === false) {
      setTimeout(() => AccordianClick(undefined), 1000);
    }
  });

  function AccordianClick(_: any) {
    let content = accordEl.current!;
    let canPlus = canPlusEl.current!;

    if (content.classList.contains('accordian-expand')) {
      content.classList.add('accordian-contract');
      content.classList.remove('accordian-expand');

      canPlus.style.transform = "rotate(90deg)";

      return;
    }

    content.classList.add('accordian-expand');
    content.classList.remove('accordian-contract');
    canPlus.style.transform = "rotate(0deg)";
  }

  return (
    <div className='member-accordian' >
      <button className='accordian-button flex-left' onClick={(event) => AccordianClick(event)}>
        <h2><b>{props.title}</b></h2>
        <FontAwesomeIcon icon={['fas', 'minus']} className='accordian-icon' />
        <FontAwesomeIcon icon={['fas', 'minus']} className='accordian-icon canPlus' ref={canPlusEl} />
      </button>
      <div className="accordian-content accordian-expand" ref={accordEl}>
        {
          props.list.map((member, index) => (
            <div key={index} className="grid-item">
              {props.images[member.name] &&
                (<MemberCard member={member} imageUrl={props.images[member.name]} />)}
            </div>
          ))
        }
      </div>
    </div>
  )
}
